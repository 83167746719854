<template>
  <div class="">
    <import-template
    :uploadUrl="uploadUrl"
    uploadLabel="导入维保单位"
    :downloadUrl="downloadUrl"
    :autoUpload="true"
    downloadText="下载维保单位关联模板"
    :submitUrl="submitUrl"
    @onSuccess="onSuccess"
    >
      <template #remark>
        最大支持10MB的excel文件，超过请拆分导入
      </template>
    </import-template>
  </div>
</template>

<script>
import { importMaintainURL, downLoadMaintainURL, importExcel } from './api.js'
import { ImportTemplate } from '@/components/bussiness'
export default {
  name: 'DeviceConfigImportMaintain',
  components: {
    ImportTemplate
  },
  data () {
    return {
      uploadUrl: importExcel,
      downloadUrl: downLoadMaintainURL,
      submitUrl: importMaintainURL
    }
  },
  mounted () {
    this.$setBreadcrumb('导入维保单位关联分类')
  },
  methods: {
    onSuccess (res) {
    }
  }
}
</script>

<style lang="css" scoped>
</style>
